<template>
  <div>
    <div class="card border-0 bg-light shadow-sm h-100 mb-3">
      <div class="card-body">
        <h5 class="card-title fw-bold text-primary text-capitalize">Snippet Offerings</h5>
        <p class="alert alert-primary mt-1">
          <i class="far fa-info-circle me-1 me-1"></i>
          Configure which services and practitioners are bookable via this snippet.
        </p>
        <div class="row">
          <div class="col">
            <toggle-button v-model="snippet.all_services"
                           class="my-auto"
                           :color="'#03006b'" />
            <label class="custom-control-label ms-1">
              All public services
            </label>
            <div v-if="!snippet.all_services">
              <select-service @input="addService" class="mt-2" />
              <drag-and-drop-grid v-model="snippet.services" />
            </div>
          </div>
          <div class="col">
            <toggle-button v-model="snippet.all_packages"
                           class="my-auto"
                           :color="'#03006b'" />
            <label class="custom-control-label ms-1">
              All public packages
            </label>
            <div v-if="!snippet.all_packages">
              <select-package @input="addPackage" class="mt-2" />
              <drag-and-drop-grid v-model="snippet.packages" />
            </div>
          </div>
          <div class="col">
            <toggle-button v-model="snippet.all_practitioners"
                           class="my-auto"
                           :color="'#03006b'" />
            <label class="custom-control-label ms-1">
              All available practitioners
            </label>
            <div v-if="!snippet.all_practitioners">
              <select-user @input="addPractitioner" class="mt-2" />
              <drag-and-drop-grid v-model="snippet.practitioners" />
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="card border-0 bg-light shadow-sm h-100 mb-3">
      <div class="card-body">
        <h5 class="card-title fw-bold text-primary text-capitalize">Terms & Conditions</h5>
        <p class="alert alert-primary mt-1">
          <i class="far fa-info-circle me-1 me-1"></i>
          (Optional) Issue terms & conditions to clients who book via this snippet.
        </p>
        <div class="row">
          <div class="col-4">
            <toggle-button v-model="showTncs"
                           class="my-auto"
                           :color="'#03006b'" />
            <label class="custom-control-label ms-1">
              Issue Terms & Conditions
            </label>
            <div v-if="showTncs">
              <select-tnc @input="addTnc" class="mt-2"/>
              <drag-and-drop-grid v-model="snippet.clinic_terms" />
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import SelectService from "../../../../../components/services/SelectService";
import DragAndDropGrid from "./components/DragAndDropGrid";
import SelectPackage from "../../../../../components/packages/SelectPackage";
import SelectUser from "../../../../../components/users/SelectUser";
import SelectTnc from "../../../../../components/tncs/SelectTnc";
export default {
  components: {SelectTnc, SelectUser, SelectPackage, DragAndDropGrid, SelectService},
  props: ["value"],
  data() {
    return {
      snippet: this.value,
      showTncs: false
    }
  },
  watch: {
    snippet() {
      this.$emit("input", this.snippet);
    },
    value() {
      this.snippet = this.value;
    }
  },
  computed: {
  },
  methods: {
    addService(service) {
      this.snippet.services.push(service);
    },
    addPackage(p) {
      this.snippet.packages.push(p);
    },
    addPractitioner(p) {
      this.snippet.practitioners.push(p);
    },
    addTnc(tnc) {
      this.snippet.clinic_terms.push(tnc);
    }
  },
}
</script>