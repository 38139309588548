<template>
  <div class="modal fade" tabindex="-1" role="dialog" id="previewSnippetModal">
    <div class="preview-modal-dialog modal-dialog modal modal-xl modal-dialog-centered" role="document">
      <div class="preview-modal-content modal-content bg-white">
        <div class="preview-modal-body modal-body">
          <h4 class="fw-bold text-primary mb-3">
            Preview Snippet: <span class="fw-normal">{{snippet.name}}</span>
          </h4>

          <div v-if="snippetScriptLoaded">
            <swandoola-appointment-booker
                class="sizeReduce"
                :uid="snippet.id"
            />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: ["snippet"],
  data() {
    return {
      snippetScriptLoaded: false
    }
  },
  mounted() {
    $("#previewSnippetModal").modal("show");

    $("#previewSnippetModal").on("hide.bs.modal", (e) => {
      this.$emit("closed");
    });

    let recaptchaScript = document.createElement('script')
    recaptchaScript.setAttribute('src', '/snippets/v1_8/swandoola.js');
    document.head.appendChild(recaptchaScript)
    this.snippetScriptLoaded = false;
    //removes an error about the missing (not yet loaded) appointment-booker
    setTimeout(() => {
      this.snippetScriptLoaded = true;
    }, 1000); //1s
  },
  computed: {
  },
  methods: {
    getAdminRedirectBackFromPaymentPageUrl() {
      return window.location.href;
    },
  }
};
</script>

<style>
.preview-modal-dialog,
.preview-modal-content {
  /* 80% of window height */
  height: 80%;
}

.preview-modal-body {
  /* 100% = dialog height, 120px = header + footer */
  max-height: calc(100% - 120px);
  overflow-y: scroll;
}
</style>