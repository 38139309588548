<template>
  <div class="card border-0 bg-light shadow-sm h-100 mb-3">
    <div class="card-body">
      <h5 class="card-title fw-bold text-primary text-capitalize">Colour Scheme</h5>
      <p class="alert alert-primary mt-1">
        <i class="far fa-info-circle me-1 me-1"></i>
        Configure your snippet colour scheme. You can optionally choose to upload a background image, otherwise the specified background colour will be used.
      </p>
      <div class="row">
        <div class="col-2">
          <h6 class="fw-bold text-primary">Background</h6>
          <label>Background colour</label>
          <v-input-colorpicker v-model="snippet.color_background" class="mb-2" />

          <label>Background Image</label>
          <input
              type="file"
              id="backgroundHeaderImage"
              @change="(e) => uploadBackgroundImage(e)"
          />
          <br />
          <small><b>Supported Files:</b> JPG, PNG, GIF (Max 10MB)</small>
          <div v-if="snippet.background_image_url">
            <i v-if="snippet.background_image_url"
               class="far fa-trash me-1"
               @click="snippet.background_image_url=null"></i>
            <img :src="snippet.background_image_url" width="100"/>
          </div>
        </div>
        <div class="col-2">
          <h6 class="fw-bold text-primary">General</h6>
          <label>Primary colour</label>
          <v-input-colorpicker v-model="snippet.color_main" class="mb-2" />
          <label>Warning colour</label>
          <v-input-colorpicker v-model="snippet.color_warning" class="mb-2" />
          <label>Error colour</label>
          <v-input-colorpicker v-model="snippet.color_error" />
        </div>
        <div class="col-2">
          <h6 class="fw-bold text-primary">Buttons & Icons</h6>
          <label>Active colour</label>
          <v-input-colorpicker v-model="snippet.color_btn_active" class="mb-2" />
          <label>Disabled colour</label>
          <v-input-colorpicker v-model="snippet.color_btn_disabled" class="mb-2" />
          <label>Background colour</label>
          <v-input-colorpicker v-model="snippet.color_btn_background" class="mb-2" />
          <label>Font colour</label>
          <v-input-colorpicker v-model="snippet.color_btn_text" />
        </div>
        <div class="col-2">
          <h6 class="fw-bold text-primary">Calendar</h6>
          <label>Available days highlight</label>
          <v-input-colorpicker v-model="snippet.color_calendar_active_days" />
        </div>
        <div class="col-2">
          <h6 class="fw-bold text-primary">Dropdowns</h6>
          <label>Border colour</label>
          <v-input-colorpicker v-model="snippet.color_dropdown_border" class="mb-2" />
          <label>Text colour</label>
          <v-input-colorpicker v-model="snippet.color_dropdown_text" />
        </div>
        <div class="col-2">
          <h6 class="fw-bold text-primary">Lists</h6>
          <label>Text colour</label>
          <v-input-colorpicker v-model="snippet.color_list_text" />
        </div>
</div>
    </div>
  </div>
</template>

<script>
import InputColorPicker from 'vue-native-color-picker'

export default {
  components: {
    "v-input-colorpicker": InputColorPicker,
  },
  props: ["value"],
  data() {
    return {
      snippet: this.value
    }
  },
  watch: {
    snippet() {
      this.$emit("input", this.snippet)
    },
    value() {
      this.snippet = this.value;
    }
  },
  computed: {
    formHeaders() {
      return {
        headers: {
          "Content-Type": "multipart/form-data",
          Authorization: "Bearer " + this.$store.getters['auth/token'],
        },
      }
    },
  },
  mounted() {
  },
  methods: {
    uploadBackgroundImage(e) {
      const file = e.target.files[0];
      if (!file) {
        return;
      }
      let formData = new FormData();
      formData.append("file", file);
      formData.append("clinic_id", this.$store.getters['auth/user'].clinic.id);

      this.$axios.post(
              process.env.VUE_APP_API_URL +
              `/settings/snippet/upload-background-picture`,
              formData,
              this.formHeaders
          ).then(({ data }) => {
            this.snippet.background_image_url = data.url;
            document.getElementById("backgroundImage").value = "";
          });
    }
  }
}
</script>


<style>
.pickerParent {
  pointer-events: none;
}
.pickerParent input.icp__input {
  pointer-events: auto;
}
input.icp__input {
  width: 100% !important;
}
</style>