<template>
  <div class="card cursor-pointer"
       :class="selected ? 'bg-primary text-white fw-bold' : ''">
    <div class="card-body">
      <ol>
        <li v-if="captureClientDetails==='first'" class="mb-2">
          <div class="fa-stack">
            <i class="fas fa-circle fa-stack-2x text-secondary"></i>
            <span class="text-white fw-bold fa-stack-1x">1</span>
          </div>
          Client Details Form
        </li>
        <li v-for="(step, index) in steps"
            class="fw-bold mb-2">
          <div class="fa-stack">
            <i class="fas fa-circle fa-stack-2x text-secondary"></i>
            <span class="text-white fw-bold fa-stack-1x">
              {{ index + (captureClientDetails === 'first' ? 2 : 1) }}
            </span>
          </div>
          Select <span class="text-capitalize">{{ step }}</span>
        </li>
        <li v-if="captureClientDetails==='last'" class="mb-2">
          <div class="fa-stack">
            <i class="fas fa-circle fa-stack-2x text-secondary"></i>
            <span class="text-white fw-bold fa-stack-1x">
              {{ steps.length + (captureClientDetails === 'first' ? 1 : 1) }}
            </span>
          </div>
          Client Details Form
        </li>
        <li>
          <div class="fa-stack">
            <i class="fas fa-circle fa-stack-2x text-secondary"></i>
            <span class="text-white fw-bold fa-stack-1x">
              5
            </span>
          </div>
          Payment & Confirmation
        </li>
      </ol>
    </div>
  </div>
</template>

<script>
export default {
  props: ["steps", "selected", "captureClientDetails"]
}
</script>