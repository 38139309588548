<template>
  <div class="card border-0 bg-light shadow-sm h-100 mb-3">
    <div class="card-body">
      <h5 class="card-title fw-bold text-primary text-capitalize">Snippet Options</h5>
      <p class="alert alert-primary mt-1">
        <i class="far fa-info-circle me-1 me-1"></i>
        Give your website snippet an identifiable name and optionally provide a description of its use.
      </p>

      <div class="row mb-3">
        <div class="col-6">
          <label>Name</label>
          <input type="text"
                 class="form-control"
                 placeholder="Provide a name for this snippet..."
                 v-model="snippet.name"
                 required />
        </div>
      </div>

      <div class="row mb-3">
        <div class="col">
          <label>Description</label>
          <textarea rows="4"
                    class="form-control"
                    placeholder="(Optional) Provide a description for this website snippet..."
                    v-model="snippet.description" />
        </div>
      </div>


      <p class="alert alert-primary mt-1">
        <i class="far fa-info-circle me-1 me-1"></i>
        Configure the order in which clients progress through the booking process.
      </p>

      <div class="row mb-1">
        <div class="col">
          <label class="text-primary fw-bold d-flex">
            Step 1
            <i class="far fw-normal fa-arrow-circle-right ms-auto my-auto" />
          </label>
          <select class="form-control"
                  required
                  v-model="snippet.steps[0]"
                  @change="validateSteps(0)">
            <option value="client_details">Client Details Form</option>
            <option value="practitioner">Practitioner selection</option>
            <option value="service">Service selection</option>
          </select>
        </div>
        <div class="col">
          <label class="text-primary fw-bold d-flex">
            Step 2
            <i class="far fw-normal fa-arrow-circle-right ms-auto my-auto" />
          </label>
          <select class="form-control"
                  required
                  v-model="snippet.steps[1]"
                  @change="validateSteps(1)">
            <option value="service">Service selection</option>
            <option value="practitioner">Practitioner selection</option>
            <option value="date" v-if="snippet.steps[0] !== 'client_details' && snippet.steps[0] !== 'practitioner'">Date selection</option>
          </select>
        </div>
        <div class="col">
          <label class="text-primary fw-bold d-flex">
            Step 3
            <i class="far fw-normal fa-arrow-circle-right ms-auto my-auto" />
          </label>
          <select class="form-control"
                  required
                  v-model="snippet.steps[2]"
                  @change="validateSteps(2)">
            <option value="date" v-if="snippet.steps[1] !== 'practitioner'">Date selection</option>
            <option value="service">Service selection</option>
            <option value="practitioner">Practitioner selection</option>
          </select>
        </div>
        <div class="col">
          <label class="text-primary fw-bold d-flex">
            Step 4
            <i class="far fw-normal fa-arrow-circle-right ms-auto my-auto" />
          </label>
          <select class="form-control"
                  required
                  v-model="snippet.steps[3]"
                  @change="validateSteps(3)">
            <option value="client_details">Client Details Form</option>
            <option value="date">Date selection</option>
            <option value="practitioner">Practitioner selection</option>
          </select>
        </div>
        <div class="col">
          <label class="text-primary fw-bold d-flex">
            Step 5
            <i class="far fw-normal fa-check-circle ms-auto my-auto" />
          </label>
          <select class="form-control"
                  required
                  v-model="snippet.steps[4]"
                  @change="validateSteps(4)">
            <option value="payment">Payment & Confirmation</option>
          </select>
        </div>
      </div>

      <div class="row mb-3">
        <div class="col" v-for="step in snippet.steps">
          <div class="card shadow-sm border-0 h-100" :class="step ? 'bg-white' : 'bg-light'">
            <div class="card-body d-flex">
              <i class="far fa-3x text-primary py-2 my-auto mx-auto"
                 v-if="step"
                 :class="{
                    'fa-users': step === 'practitioner',
                    'fa-hand-holding-heart': step === 'service',
                    'fa-clipboard-user': step === 'client_details',
                    'fa-calendar-alt': step === 'date',
                    'fa-shopping-cart': step === 'payment',
                 }"
              />
              <p class="my-auto mx-auto small fst-italic" v-if="!step">Select an option</p>
            </div>
          </div>
        </div>
      </div>

      <div class="row">
        <div class="col-5">
          <label>
            Success Redirect URL
            <i class="far fa-info-circle text-primary ms-1"
               v-tooltip:bottom="'URL to redirect to after successful booking'" />
          </label>
          <input
              type="text"
              class="form-control"
              placeholder="https://yourwebsite.com/thankyou?example=123"
              v-model="snippet.redirect_url"
              required
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: ["value"],
  data() {
    return {
      snippet: this.value
    }
  },
  watch: {
    snippet() {
      this.$emit("input", this.snippet)
    },
    value() {
      this.snippet = this.value;
    },
  },
  methods: {
    validateSteps(stepChanged) {
      console.log("validateSteps");

      //get the val changed
      let changed = this.snippet.steps[stepChanged]

      //reset any other values which are the same
      for (let i = 0; i < this.snippet.steps.length; i++) {
        if (i !== stepChanged) {
          if (this.snippet.steps[i] === changed) {
            this.snippet.steps[i] = null;
          }
        }
      }
    }
  },
  components: { },
}
</script>