<template>
  <div class="card border-0 bg-light shadow-sm h-100 mb-3">
    <div class="card-body">
      <h5 class="card-title fw-bold text-primary text-capitalize">Confirmation Messages</h5>
      <p class="alert alert-primary mt-1">
        <i class="far fa-info-circle me-1 me-1"></i>
        Specify messages to be shown to clients upon successful booking.
      </p>
      <div class="row mb-3">
        <div class="col-6">
          <label>Service was free ({{ msgPaymentFreeServiceLength }}/200 chars)</label>
          <textarea
            class="form-control"
            placeholder="(Optional) Provide a message to be shown upon successfull booking of a free service..."
            rows="3"
            v-model="snippet.msg_payment_free_service" />
        </div>
        <div class="col-6">
          <label>Payment Additional Information ({{ snippet.msg_payment_additional_info.length }}/500 chars)</label>
          <textarea
            class="form-control"
            placeholder="(Optional) Provide additional payment instructions..."
            rows="3"
            v-model="snippet.msg_payment_additional_info" />
        </div>
      </div>
      <div class="row">
        <div class="col-12">
          <label>Thank you - Successful ({{ snippet.msg_thank_you_success.length }}/200 chars)</label>
          <textarea
            class="form-control"
            placeholder="(Optional) Provide a thank you message to be shown upon successful booking..."
            rows="3"
            v-model="snippet.msg_thank_you_success" />
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  props: ["value"],
  data() {
    return {
      snippet: this.value,
    }
  },
  watch: {
    snippet() {
      this.$emit("input", this.snippet);
    },
    value() {
      this.snippet = this.value;
    }
  },
  computed: {
    msgPaymentFreeServiceLength() {
      return this.snippet.msg_payment_free_service.length;
    }
  },
  methods: {
  },
  created() {
    this.snippet.redirect_url = this.snippet.redirect_url ?? '';
    this.snippet.msg_payment_free_service = this.snippet.msg_payment_free_service ?? '';
    this.snippet.msg_payment_additional_info = this.snippet.msg_payment_additional_info ?? '';
    this.snippet.msg_thank_you_success = this.snippet.msg_thank_you_success ?? '';
  }
}
</script>
