<template>
  <div class="grd">
    <draggable
      v-model="localElements"
      @start="drag=true"
      @end="drag=false"
    >
      <div v-for="(element, index) in localElements" :key="index">
        <div class="card my-1">
          <div class="card-body d-flex py-2">
            <div class="flex-grow-1" style="cursor: move">
              {{element.name ? element.name : element.title}}
            </div>
            <i class="far fa-trash text-danger cursor-pointer ms-auto my-auto" @click="deleteClick(index)"></i>
          </div>
        </div>
      </div>
    </draggable>
  </div>
</template>

<script>
import draggable from 'vuedraggable';
export default {
  components: {
    draggable,
  },
  props: [
    "value",
    "title",
  ],
  data() {
    return {
      localElements: this.value ?? []
    }
  },
  watch: {
    localElements() {
      this.$emit("input", this.localElements);
    },
    value() {
      this.localElements = this.value;
    }
  },
  methods: {
    deleteClick(index) {
      this.localElements.splice(index, 1);
    },
  },
}
</script>
<style scoped>
div.row.move {
  cursor: move;
}
</style>