<template>
  <div>
    Capture Client Details
    <div class="mb-2">
      <toggle-button v-model="firstStep"
                     class="me-1"
                     :sync="true"
                     :color="'#03006b'" />
      <label>First Step</label>
    </div>
    <div class="mb-3">
      <toggle-button v-model="lastStep"
                     class="me-1"
                     :sync="true"
                     :color="'#03006b'" />
      <label>Last Step</label>
    </div>

  </div>
</template>

<script>
export default {
  props: ["value"],
  data() {
    return {
      captureClientDetails: this.value,
      firstStep: (this.value === 'first'),
      lastStep: (this.value === 'last')
    }
  },
  watch: {
    firstStep() {
      this.lastStep = !this.firstStep;
      this.captureClientDetails = this.firstStep ? 'first' : 'last';
    },
    lastStep() {
      this.firstStep = !this.lastStep;
      this.captureClientDetails = this.firstStep ? 'first' : 'last';
    },
    captureClientDetails() {
      this.$emit("input", this.captureClientDetails);
    },
    value() {
      this.captureClientDetails = this.value;
    }
  },
  mounted() {
    if (!this.captureClientDetails) {
      this.captureClientDetails = "firstStep"
      this.firstStep = true;
    }
  },
  components: { },
}
</script>