<template>
  <div class="modal fade" tabindex="-1" role="dialog" id="snippetEmbedCodeModal">
    <div class="modal-dialog modal modal-xl modal-dialog-centered" role="document">
      <div class="modal-content bg-white">
        <div class="modal-body">
          <h4 class="fw-bold text-primary mb-3">
            Embed Snippet: <span class="fw-normal">{{snippet.name}}</span>
          </h4>
          <div class="card border-0 alert-info shadow-sm h-100 mb-3">
            <div class="card-body">
              <h5 class="fw-bold text-primary">
                Step 1: <span class="fw-normal">Import Swandoola Javascript</span>
              </h5>
              <p class="card-text">
                <i class="far fa-info-circle me-1 me-1"></i>
                The first step to adding the snippets to your website is to import
                the Javascript file that enables the functionality. On most
                websites this would be done by placing the script import tag just
                before the end of the
                <span class="text-danger">&lt;/head&gt;</span> section of the
                website.
              </p>
              <div class="row mb-3">
                <div class="col-12">
                  <pre id="importToCopy" class="text-danger">&lt;script type="text/javascript" src="https://app.swandoola.com/snippets/v1_8/swandoola.js"&gt;&lt;/script&gt;</pre>
                </div>
              </div>
            </div>
          </div>
          <div class="card border-0 alert-info shadow-sm h-100 mb-3">
            <div class="card-body">
              <h5 class="fw-bold text-primary">
                Step 2: <span class="fw-normal">Paste the Snippet code for {{snippet.name}}</span>
              </h5>
              <p class="card-text">
                <i class="far fa-info-circle me-1 me-1"></i>
                Paste the following code snippet where you would like the booking snippet to appear on your website.
              </p>
              <div class="row">
                <div class="col-10">
                  <textarea
                      id="healthFormToCopy"
                      class="form-control"
                      rows="3"
                      disabled
                      :value="embedCode"
                  />
                </div>
                <div class="col-2">
                  <button
                      class="btn btn-sm btn-outline-primary w-100"
                      v-clipboard:copy="embedCode"
                  >
                    <i class="far fa-copy me-2"></i>Copy to clipboard
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: ["snippet"],
  data() {
    return {
    }
  },
  mounted() {
    $("#snippetEmbedCodeModal").modal("show");

    $("#snippetEmbedCodeModal").on("hide.bs.modal", (e) => {
      this.$emit("closed");
    });
  },
  computed: {
    embedCode() {
      return '<swandoola-appointment-booker uid="' + this.snippet.id + '" />';
    }
  },
  methods: {

  }
};
</script>

<style>
</style>