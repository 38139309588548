<template>
  <div class="card border-0 bg-light shadow-sm h-100 mb-3">
    <div class="card-body">
      <h5 class="card-title fw-bold text-primary text-capitalize">Snippet Preview</h5>
      <p class="alert alert-primary mt-1">
        <i class="far fa-info-circle me-1 me-1"></i>
        Preview your snippet before saving changes. Changes are not live until you save.
      </p>

      <swandoola-appointment-booker
        class="sizeReduce"
        :clinic="$store.getters['auth/user'].clinic.id"
        :scenario-text="translatedStepsToOldWay"
        :payment-free-service="snippet.msg_payment_free_service"
        :payment-additional-information="snippet.msg_payment_additional_info"
        :thank-you-success-message="snippet.msg_thank_you_success"
        :dropdown-view-enabled="false"
        :redirect="getAdminRedirectBackFromPaymentPageUrl()"
        :client-details-captured="snippet.capture_client_details"
        :header-on="snippet.enable_header"
        :clinic-logo-align="snippet.align_clinic_logo"
        :clinic-logo-visible="snippet.enable_clinic_logo"
        :clinic-name-visible="snippet.enable_clinic_name"
        default-step-type="normal"
        :offering-photo="snippet.enable_service_photo"
        :offering-description="snippet.enable_service_description"
        :practitioner-photo="snippet.enable_practitioner_photo"
        :practitioner-bio="snippet.enable_practitioner_bio"
        :sorted-filtered-services="snippet.services"
        :sorted-filtered-packages="snippet.packages"
        :sorted-filtered-practitioners="snippet.practitioners"
      />

      <!--

      :custom-styles='$stash.lookAndFeel ? JSON.stringify($stash.lookAndFeel) : ""'

      -->
    </div>
  </div>
</template>
<script>
export default {
  props: ["snippet"],
  data() {
    return {
    }
  },
  computed: {
    translatedStepsToOldWay() {
      let newSteps = this.snippet.steps.filter((item) => {
        return item !== 'client_details' && item !== 'payment'
      });

      newSteps = newSteps.map((item) => {
        if (item === 'practitioner') {
          return 'practitioners'
        } else if (item === 'service') {
          return 'services';
        } else if (item === 'date') {
          return 'calendar';
        }
        return item;
      })

      newSteps.push('confirm');
      return newSteps.toString()
    }
  },
  watch: {
  },
  methods: {
    getAdminRedirectBackFromPaymentPageUrl() {
      return window.location.href;
    },
  }
}
</script>
<style scoped>
  .sizeReduce {
    display: block;
    overflow: hidden;
    position: relative;
    transform-origin: 0% 0% 0px;
  }
</style>