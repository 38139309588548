<template>
  <div class="row">
    <div class="col-md-4"
         v-for="scenario in availableScenarios"
         @click="selectedScenario=scenario">
      <scenario-card :steps="scenario"
                     :capture-client-details="captureClientDetails"
                     :selected="selectedScenario === scenario"/>
    </div>
  </div>
</template>

<script>
import ScenarioCard from "./components/ScenarioCard";
export default {
  props: ["value", "captureClientDetails"],
  data() {
    return {
      availableScenarios: [
        ['practitioner','service','date'],
        ['service','practitioner','date'],
        ['service','date','practitioner'],
      ],
      selectedScenario: this.value
    }
  },
  watch: {
    selectedScenario() {
      this.$emit("input", this.selectedScenario);
    },
    value() {
      this.selectedScenario = this.value;
    }
  },
  components: { ScenarioCard },
}
</script>