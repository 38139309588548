<template>
  <div class="container-fluid">
    <div class="row mb-3">
      <div class="col my-auto d-flex">
        <h4 class="text-primary fw-bold mb-0 my-auto">
          Appointment Booker Website Snippets
          <span class="badge bg-info my-auto ms-1">BETA</span>
        </h4>
        <button class="btn btn-outline-primary ms-auto my-auto"
                v-if="!formVisible"
                @click="showAdd=true">
          <i class="far fa-plus me-1" />
          Create new Appointment Booker snippet
        </button>
        <button class="btn btn-outline-primary ms-auto my-auto"
                v-else
                @click="showAdd=false; editSnippet=null">
          <i class="far fa-arrow-left me-1" />
          Go Back
        </button>
      </div>
    </div>

    <p class="alert alert-primary text-start" v-if="!formVisible">
      <i class="far fa-info-circle"></i>
      Appointment booking snippets can be placed on your website, allowing clients to book and pay for services and packages directly through your website.
    </p>

    <snippet-embed-code v-if="showEmbedCode"
                        :snippet="showEmbedCode"
                        @closed="showEmbedCode=null" />

    <preview-snippet v-if="previewSnippet"
                     :snippet="previewSnippet"
                     @closed="previewSnippet=null" />

    <loading v-if="loading" />

    <div v-else>
      <div v-if="showAdd">
        <form @submit.prevent="createSnippet">
          <appointment-snippet-config-form v-model="newSnippet" />
        </form>
      </div>
      <div v-else-if="editSnippet">
        <form @submit.prevent="updateSnippet">
          <appointment-snippet-config-form v-model="editSnippet" />
        </form>
      </div>
      <div v-else>
        <div class="row">
          <div class="col-8 mx-auto">
            <h4 class="fw-bold text-primary">My Snippets</h4>
            <div class="card bg-light border-0">
              <div class="card-body">
                <div v-if="snippets.length === 0" class="text-center">
                  <p>No appointment booker snippets found.</p>
                  <button class="btn btn-outline-primary" @click="showAdd=true">
                    <i class="far fa-plus me-1" />
                    Create new Appointment Booker snippet
                  </button>
                </div>
                <div v-else>
                  <div class="card">
                    <table class="table table-hover mb-0">
                      <tbody>
                      <tr v-for="s in snippets" :key="s.id">
                        <td class="align-middle text-nowrap">
                          {{s.name}}
                        </td>
                        <td class="align-middle text-end text-right text-nowrap">
                          <button class="btn btn-sm btn-light ms-2" @click="previewSnippet=s">
                            <i class="fa fa-eye" v-tooltip:bottom="'Preview'" />
                          </button>
                          <button class="btn btn-sm btn-success text-white ms-2" @click="showEmbedCode=s">
                            <i class="fa fa-code me-1" />
                            Get Embed Code
                          </button>
                          <button class="btn btn-sm btn-light ms-2" @click="editSnippet=s">
                            <i class="fa fa-cog text-primary me-1" />
                            Configure
                          </button>
                          <button class="btn btn-sm btn-light ms-2" @click="deleteSnippet(s)">
                            <i class="fa fa-trash text-danger me-1" />
                          </button>
                        </td>
                      </tr>
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import AppointmentSnippetConfigForm from "./AppointmentSnippetConfigForm";
import SnippetEmbedCode from "./partials/SnippetEmbedCode";
import PreviewSnippet from "./partials/PreviewSnippet";
export default {
  components: {PreviewSnippet, SnippetEmbedCode, AppointmentSnippetConfigForm},
  props: [],
  data() {
    return {
      loading: true,
      snippets: [],
      showAdd: false,
      editSnippet: null,
      previewSnippet: null,
      showEmbedCode: null,
      newSnippet: {
        steps: [
            'client_details',
            'practitioner',
            'service',
            'date',
            'payment'
        ],
        clinic_terms: [],
        all_services: true,
        all_packages: true,
        all_practitioners: true,
        services: [],
        packages: [],
        practitioners: [],
        align_clinic_logo: 'center',
        msg_payment_free_service: '',
        msg_payment_additional_info: '',
        msg_thank_you_success: '',
        color_main: '#33ddbb',
        color_warning: '#FFBF00',
        color_error: '#FF4000',
        color_btn_active: '#33ddbb',
        color_btn_disabled: '#bbbbbb',
        color_btn_background: '#ffffff',
        color_btn_text: '#111111',
        color_calendar_active_days: '#33ddbb',
        color_dropdown_border: '#cccccc',
        color_dropdown_text: '#cccccc',
        color_list_text: '#9999999',
        color_background: '#ffffff',
        color_header_background: '#ffffff',
      }
    };
  },
  computed: {
    formVisible() {
      return this.showAdd || this.editSnippet
    }
  },
  methods: {
    fetchSnippets() {
      this.$axios.get(process.env.VUE_APP_API_URL + '/appointment-snippet-configs').then(({data}) => {
        this.snippets = data;
        this.loading = false;
      });
    },
    createSnippet() {
      this.$axios.post(
          process.env.VUE_APP_API_URL + '/appointment-snippet-configs', this.newSnippet
      ).then(({data}) => {
        this.$EventBus.$emit("alert", data);
        this.fetchSnippets();
        this.showAdd=false;
      })
    },
    updateSnippet() {
      this.$axios.put(
          process.env.VUE_APP_API_URL + '/appointment-snippet-configs/' + this.editSnippet.id, this.editSnippet
      ).then(({data}) => {
        this.$EventBus.$emit("alert", data);
        this.fetchSnippets();
        this.editSnippet = null;
      })
    },
    deleteSnippet(s) {
      var confirmed = confirm("Are you sure you wish to delete this website snippet? It will continue to function on any websites where it is being used, but you will no longer be able to edit it without creating a new snippet from scratch.");
      if (confirmed) {
        this.$axios.delete(
            process.env.VUE_APP_API_URL + '/appointment-snippet-configs/' + s.id
        ).then(({data}) => {
          this.$EventBus.$emit("alert", data);
          this.fetchSnippets();
        })
      }
    },
  },
  created() {
    this.fetchSnippets();
  }
};
</script>