<template>
  <div class="row mb-3">
    <div class="col">
      <div class="card border-0 bg-light shadow-sm h-100 mb-3">
        <div class="card-body">
          <h5 class="card-title fw-bold text-primary text-capitalize">Header Display Options</h5>
          <div class="row mb-2">
            <div class="col">
              <toggle-button v-model="snippet.enable_header"
                             class="my-auto"
                             :color="'#03006b'" />
              <label class="custom-control-label ms-1">
                Header Visible
              </label>
            </div>
          </div>

          <div v-if="snippet.enable_header">
            <div class="row mb-2">
              <div class="col">
                <toggle-button v-model="snippet.enable_clinic_logo"
                               class="my-auto"
                               :color="'#03006b'" />
                <label class="custom-control-label ms-1">
                  Show Clinic Logo
                </label>
              </div>
            </div>

            <div class="row mb-2" v-if="snippet.enable_clinic_logo">
              <div class="col">
                <label>Logo Alignment</label>
                <select class="form-control" v-model="snippet.align_clinic_logo">
                  <option value="left">Left</option>
                  <option value="center">Center</option>
                  <option value="right">Right</option>
                </select>
              </div>
            </div>

            <div class="row mb-2">
              <div class="col">
                <toggle-button v-model="snippet.enable_clinic_name"
                               class="my-auto"
                               :color="'#03006b'" />
                <label class="custom-control-label ms-1">
                  Show Clinic Name
                </label>
              </div>
            </div>

            <div class="row">
              <div class="col">
                <label>Background colour</label>
                <v-input-colorpicker v-model="snippet.color_header_background" class="mb-2" />
              </div>
            </div>

            <div class="row">
              <div class="col">
                <label>Background Image</label>
                <input
                    type="file"
                    id="backgroundHeaderImage"
                    @change="(e) => uploadHeaderBackgroundImage(e)"
                />
                <br />
                <small><b>Supported Files:</b> JPG, PNG, GIF (Max 10MB)</small>
                <div v-if="snippet.header_image_url">
                  <i v-if="snippet.header_image_url"
                     class="far fa-trash me-1"
                     @click="snippet.header_image_url=null"></i>
                  <img :src="snippet.header_image_url" width="100"/>
                </div>
              </div>
            </div>
          </div>

        </div>
      </div>
    </div>
    <div class="col">
      <div class="card border-0 bg-light shadow-sm h-100 mb-3">
        <div class="card-body">
          <h5 class="card-title fw-bold text-primary text-capitalize">Practitioner Display Options</h5>
          <div class="row mb-2">
            <div class="col">
              <toggle-button v-model="snippet.enable_practitioner_photo"
                             class="my-auto"
                             :color="'#03006b'" />
              <label class="custom-control-label ms-1">
                Show practitioner photos
              </label>
            </div>
          </div>

          <div class="row">
            <div class="col">
              <toggle-button v-model="snippet.enable_practitioner_bio"
                             class="my-auto"
                             :color="'#03006b'" />
              <label class="custom-control-label ms-1">
                Show practitioner bio
              </label>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="col">
      <div class="card border-0 bg-light shadow-sm h-100 mb-3">
        <div class="card-body">
          <h5 class="card-title fw-bold text-primary text-capitalize">Service Display Options</h5>
          <div class="row mb-2">
            <div class="col">
              <toggle-button v-model="snippet.enable_service_photo"
                             class="my-auto"
                             :color="'#03006b'" />
              <label class="custom-control-label ms-1">
                Show service cover images
              </label>
            </div>
          </div>

          <div class="row">
            <div class="col">
              <toggle-button v-model="snippet.enable_service_description"
                             class="my-auto"
                             :color="'#03006b'" />
              <label class="custom-control-label ms-1">
                Show service descriptions
              </label>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import InputColorPicker from 'vue-native-color-picker'

export default {
  components: {
    "v-input-colorpicker": InputColorPicker
  },
  props: ["value"],
  data() {
    return {
      snippet: this.value
    }
  },
  watch: {
    snippet() {
      this.$emit("input", this.snippet)
    },
    value() {
      this.snippet = this.value;
    }
  },
  computed: {
    formHeaders() {
      return {
        headers: {
          "Content-Type": "multipart/form-data",
          Authorization: "Bearer " + this.$store.getters['auth/token'],
        },
      }
    }
  },
  mounted() {
  },
  methods: {
    uploadHeaderBackgroundImage(e) {
      const file = e.target.files[0];
      if (!file) {
        return;
      }
      let formData = new FormData();
      formData.append("file", file);
      formData.append("clinic_id", this.$store.getters['auth/user'].clinic.id);

      this.$axios.post(
              process.env.VUE_APP_API_URL +
              `/settings/snippet/upload-header-background-picture`,
              formData,
              this.formHeaders
          ).then(({ data }) => {
            this.snippet.header_image_url = data.url;
            document.getElementById("backgroundHeaderImage").value = "";
          });

    },
  },
}
</script>


<style>
.pickerParent {
  pointer-events: none;
}
.pickerParent input.icp__input {
  pointer-events: auto;
}
input.icp__input {
  width: 100% !important;
}
</style>