<template>
    <div>
      <snippet-options v-model="snippet" />

      <snippet-filters v-model="snippet"/>

      <display-options v-model="snippet" />

      <look-and-feel v-model="snippet" />

      <payment-settings v-model="snippet" />

      <div class="d-flex mb-3">
        <button type="submit" class="btn btn-primary ms-auto">
          <i class="far fa-save me-1" />
          Save Snippet
        </button>
      </div>
    </div>
</template>

<script>
import CaptureClientDetails from "./partials/CaptureClientDetails";
import Preview from "./partials/Preview.vue";
import SnippetFilters from "./partials/SnippetFilters.vue";
import PaymentSettings from "./partials/PaymentSettings.vue";
import ScenarioSelector from './partials/ScenarioSelector.vue';
import LookAndFeel from "./partials/LookAndFeel";
import SnippetOptions from "./partials/SnippetOptions";
import DisplayOptions from "./partials/DisplayOptions";
export default {
  props: ["value"],
  data() {
    return {
      snippet: this.value,
    };
  },
  components: {
    DisplayOptions,
    SnippetOptions,
    LookAndFeel,
    CaptureClientDetails,
    ScenarioSelector,
    PaymentSettings,
    Preview,
    SnippetFilters,
  },
};
</script>